import axios from "axios";
// import queryString from 'query-string';

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const tools = {
  /**
   * Log errors.
   */
  fetchPostLogs: (payload) => fetcher.post("/logs", payload),
};

const basic = {
  /**
   * get public invoice info
   */
  fetchGetInvoice: ({ invoiceId }) => fetcher.get(`/invoices/${invoiceId}`),
  /**
   * get carrier member van by post method
   */
  fetchPostCarrierMemberVan: ({ invoiceId, ...payload }) =>
    fetcher.post("/carrier/member-van", payload),
  /**
   * send verify email for register carrier
   */
  fetchPostVerifyEmail: (payload) => fetcher.post("/verify/email", payload),
  /**
   * get carrier consumer by post method
   */
  fetchPostCarrierConsumer: (payload) =>
    fetcher.post("/carrier/consumer", payload),
};

export default {
  tools,
  ...basic,
};
