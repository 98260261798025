import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import { reducer as dialogs } from "@e-group/redux-modules/dialogs";
import { reducer as snackbars } from "@e-group/redux-modules/snackbars";
import { reducer as entities } from "@e-group/redux-modules/entities";
import { reducer as apis } from "@e-group/redux-modules/apis";
import { publicPagesEpics } from "./publicPages";
import { components } from "./components";

export const rootEpic = combineEpics(publicPagesEpics);

// root reducer
export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    dialogs,
    snackbars,
    entities,
    apis,
    components,
  });
