import actionCreators from "redux/actionCreators";
import { createSelector } from "reselect";
import getIn from "@e-group/utils/getIn";
/** Actions */
export const {
  fetchPostVerifyEmail,
  fetchPostVerifyEmailRequest,
  fetchPostVerifyEmailSuccess,
  fetchPostVerifyEmailFailure,

  fetchPostCarrierConsumer,
  fetchPostCarrierConsumerRequest,
  fetchPostCarrierConsumerSuccess,
  fetchPostCarrierConsumerFailure,
} = actionCreators.publicPages.carrier;

/** Types */
export const FETCH_POST_VERIFY_EMAIL = fetchPostVerifyEmail().type;
export const FETCH_POST_VERIFY_EMAIL_REQUEST = fetchPostVerifyEmailRequest()
  .type;
export const FETCH_POST_VERIFY_EMAIL_SUCCESS = fetchPostVerifyEmailSuccess()
  .type;
export const FETCH_POST_VERIFY_EMAIL_FAILURE = fetchPostVerifyEmailFailure()
  .type;

export const FETCH_POST_CARRIER_CONSUMER = fetchPostCarrierConsumer().type;
export const FETCH_POST_CARRIER_CONSUMER_REQUEST = fetchPostCarrierConsumerRequest()
  .type;
export const FETCH_POST_CARRIER_CONSUMER_SUCCESS = fetchPostCarrierConsumerSuccess()
  .type;
export const FETCH_POST_CARRIER_CONSUMER_FAILURE = fetchPostCarrierConsumerFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = (state) =>
  getIn(state, ["apis", "publicPages", "carrier"], {});

const getFetchPostVerifyEmail = createSelector(getApiStates, (apiStates) =>
  getIn(apiStates, ["fetchPostVerifyEmail"], {})
);

const getFetchPostCarrierConsumer = createSelector(getApiStates, (apiStates) =>
  getIn(apiStates, ["fetchPostCarrierConsumer"], {})
);

export const getIsSendingEmail = createSelector(
  getFetchPostVerifyEmail,
  (fetchResult) => fetchResult.isLoading
);

export const getVerifyEmail = createSelector(
  getFetchPostVerifyEmail,
  (fetchResult) => fetchResult.response
);

export const getCarrierConsumer = createSelector(
  getFetchPostCarrierConsumer,
  (fetchResult) => fetchResult.response
);

export const getCarrierConsumerError = createSelector(
  getFetchPostCarrierConsumer,
  (fetchResult) => fetchResult.error
);
