import { combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";
import {
  fetchPostVerifyEmailRequest,
  fetchPostVerifyEmailSuccess,
  fetchPostVerifyEmailFailure,
  fetchPostCarrierConsumerRequest,
  fetchPostCarrierConsumerSuccess,
  fetchPostCarrierConsumerFailure,
  FETCH_POST_VERIFY_EMAIL,
  FETCH_POST_CARRIER_CONSUMER,
} from "./redux";

export const fetchPostVerifyEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_VERIFY_EMAIL,
  apiName: "fetchPostVerifyEmail",
  fetchRequest: fetchPostVerifyEmailRequest,
  handleSuccess: (response) => [fetchPostVerifyEmailSuccess(true)],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostVerifyEmailFailure(error))
    ),
});

export const fetchPostCarrierConsumerEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_CARRIER_CONSUMER,
  apiName: "fetchPostCarrierConsumer",
  fetchRequest: fetchPostCarrierConsumerRequest,
  handleSuccess: (response) => [fetchPostCarrierConsumerSuccess(response.data)],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCarrierConsumerFailure(error))
    ),
});

export default combineEpics(
  fetchPostVerifyEmailEpic,
  fetchPostCarrierConsumerEpic
);
