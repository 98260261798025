import { createActions } from "redux-actions";

const actionMap = {
  COMPONENTS: {
    MUI_THEME_PROVIDER: {
      HANDLE_CHANGE_THEME_OPTIONS: undefined,
    },
  },
  PUBLIC_PAGES: {
    INVOICE: {
      FETCH_GET_INVOICE: undefined,
      FETCH_GET_INVOICE_REQUEST: undefined,
      FETCH_GET_INVOICE_SUCCESS: undefined,
      FETCH_GET_INVOICE_FAILURE: undefined,

      FETCH_POST_CARRIER_MEMBER_VAN: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_REQUEST: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_SUCCESS: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_FAILURE: undefined,
    },
    CARRIER: {
      FETCH_POST_VERIFY_EMAIL: undefined,
      FETCH_POST_VERIFY_EMAIL_REQUEST: undefined,
      FETCH_POST_VERIFY_EMAIL_SUCCESS: undefined,
      FETCH_POST_VERIFY_EMAIL_FAILURE: undefined,

      FETCH_POST_CARRIER_CONSUMER: undefined,
      FETCH_POST_CARRIER_CONSUMER_REQUEST: undefined,
      FETCH_POST_CARRIER_CONSUMER_SUCCESS: undefined,
      FETCH_POST_CARRIER_CONSUMER_FAILURE: undefined,
    },
  },
};

export default createActions(actionMap);
