import React from "react";

import { useLocation } from "react-router";
import queryString from "query-string";

export default function useLocationSearch() {
  const location = useLocation();
  const search = React.useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);

  return search;
}
