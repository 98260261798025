import React from "react";

import isEmail from "validator/lib/isEmail";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPostVerifyEmail,
  getIsSendingEmail,
  getVerifyEmail,
} from "./redux";

import Alert from "@material-ui/lab/Alert";
import Button from "@e-group/material/Button";
import Main from "@e-group/material-layout/Main";
import {
  Container,
  makeStyles,
  Paper,
  Typography,
  Divider,
  TextField,
  Fade,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
  content: {
    padding: theme.spacing(2, 0),
  },
  grid: {
    display: "flex",
  },
  emailField: {
    flex: 1,
  },
}));

const Carrier: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSendingEmail = useSelector(getIsSendingEmail);
  const verifyEmail = useSelector(getVerifyEmail);
  const [userEmail, setUserEmail] = React.useState("");
  const [isValid, setIsValid] = React.useState(true);
  const [helperText, setHelperText] = React.useState("");
  const [sendSuccess, setSendSuccess] = React.useState(false);

  React.useEffect(() => {
    if (verifyEmail) {
      setSendSuccess(true);
    }
  }, [verifyEmail]);

  const handleChange = (e) => {
    if (isEmail(userEmail)) {
      setHelperText("");
      setIsValid(true);
    }
    setUserEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHelperText("");
    if (!isEmail(userEmail)) {
      setHelperText("Email 格式錯誤");
      setIsValid(false);
    } else {
      dispatch(
        fetchPostVerifyEmail({
          userEmail,
        })
      );
    }
  };

  return (
    <Main>
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center" gutterBottom>
            電子發票歸戶設定
          </Typography>
          <Typography variant="body1" align="center">
            請輸入您的 Email
            並且收到驗證信後，前往財政部電子發票整合服務平台完成驗證。
          </Typography>
          <div className={classes.content}>
            <form onSubmit={handleSubmit}>
              <div className={classes.grid}>
                <div className={classes.emailField}>
                  <TextField
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    error={!isValid}
                    helperText={helperText}
                    disabled={isSendingEmail || sendSuccess}
                  />
                </div>
                <div>
                  <Button
                    loading={isSendingEmail}
                    MuiButtonProps={{
                      type: "submit",
                      disableElevation: true,
                      color: "primary",
                      size: "large",
                      disabled: isSendingEmail || sendSuccess,
                    }}
                  >
                    送出
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <Divider />
          <div className={classes.content}>
            <Typography variant="body2" color="textSecondary">
              eGroup 發票將歸戶到您的共通性載具
            </Typography>
          </div>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            1. 請輸入您的 Email 作為 eGroup
            會員載具發票資料，點選確認後將發送信件至您的 Email。
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            2.
            收到驗證信後，點擊前往連結，前往財政部電子發票整合服務平台完成驗證。
          </Typography>
        </Paper>
        <Fade in={sendSuccess}>
          <Alert severity="success" className={classes.alert}>
            信件發送完成！
          </Alert>
        </Fade>
      </Container>
    </Main>
  );
};

export default Carrier;
