import { combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import makeBasicFetchEpic from "@e-group/utils/makeBasicFetchEpic";
import {
  fetchGetInvoice,
  fetchGetInvoiceRequest,
  fetchGetInvoiceSuccess,
  fetchGetInvoiceFailure,
  fetchPostCarrierMemberVanRequest,
  fetchPostCarrierMemberVanSuccess,
  fetchPostCarrierMemberVanFailure,
  FETCH_GET_INVOICE,
  FETCH_POST_CARRIER_MEMBER_VAN,
} from "./redux";

export const fetchGetInvoiceEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_INVOICE,
  apiName: "fetchGetInvoice",
  fetchRequest: fetchGetInvoiceRequest,
  handleSuccess: (response) => [fetchGetInvoiceSuccess(response.data || {})],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetInvoiceFailure(error))
    ),
});

export const fetchPostCarrierMemberVanEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_CARRIER_MEMBER_VAN,
  apiName: "fetchPostCarrierMemberVan",
  fetchRequest: fetchPostCarrierMemberVanRequest,
  handleSuccess: (response, { action }) => [
    fetchPostCarrierMemberVanSuccess(response.data || {}),
    fetchGetInvoice({
      invoiceId: action.payload.invoiceId,
    }),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCarrierMemberVanFailure(error))
    ),
});

export default combineEpics(fetchGetInvoiceEpic, fetchPostCarrierMemberVanEpic);
