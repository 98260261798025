import Root from "publicPages/Root";
import Invoice from "publicPages/Invoice";
import Carrier from "publicPages/Carrier";
import CarrierVerify from "publicPages/Carrier/CarrierVerify";

export default [
  {
    component: Root,
    routes: [
      {
        component: Invoice,
        path: "/invoices/:invoiceId",
      },
      {
        component: CarrierVerify,
        path: "/carrier/verify",
      },
      {
        component: Carrier,
        path: "/carrier",
      },
    ],
  },
];
