import React from "react";

import isEmpty from "lodash.isempty";
import displayValues from "@e-group/utils/displayValues";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoice,
  fetchPostCarrierMemberVan,
  getCarrierMemberVan,
} from "./redux";

import FixedCenter from "@e-group/material-layout/FixedCenter";
import Center from "@e-group/material-layout/Center";
import Main from "@e-group/material-layout/Main";
import {
  Paper,
  Typography,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Button,
  Hidden,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { InvoiceType } from "types";
import { format } from "date-fns";
import ccyformat from "utils/ccyformat";
import calcTotal from "utils/calcTotal";
import { useParams } from "react-router";
import useLocationSearch from "utils/useLocationSearch";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  date: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    lineHeight: "21px",
  },
  right: {
    float: "right",
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    fontWeight: 500,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const Invoice: React.FunctionComponent = () => {
  const formEl = React.useRef<HTMLFormElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoice = useSelector(getInvoice);
  const carrierMemberVan = useSelector(getCarrierMemberVan);
  const { invoiceId } = useParams();
  const { emailTokenId } = useLocationSearch();

  React.useEffect(() => {
    dispatch(
      fetchPostCarrierMemberVan({
        emailTokenId,
        invoiceId,
      })
    );
  }, [dispatch, emailTokenId, invoiceId]);

  const handleClick = () => {
    if (formEl && formEl.current) {
      formEl.current.submit();
    }
  };

  if (!emailTokenId) {
    return (
      <Center>
        <Typography variant="h6">無效的網址</Typography>
      </Center>
    );
  }

  if (!invoice) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (isEmpty(invoice)) {
    return (
      <Center>
        <Typography variant="h6">查無此發票</Typography>
      </Center>
    );
  }

  return (
    <>
      {carrierMemberVan && (
        <form
          method="POST"
          style={{ display: "none" }}
          ref={formEl}
          action={carrierMemberVan.postUrl}
        >
          <input
            type="hidden"
            name="card_ban"
            value={carrierMemberVan.card_ban}
          />
          <input
            type="hidden"
            name="card_no1"
            value={carrierMemberVan.card_no1}
          />
          <input
            type="hidden"
            name="card_no2"
            value={carrierMemberVan.card_no2}
          />
          <input
            type="hidden"
            name="card_type"
            value={carrierMemberVan.card_type}
          />
          <input type="hidden" name="token" value={carrierMemberVan.token} />
        </form>
      )}
      <Main>
        <Container maxWidth="md">
          <div className={classes.section}>
            <Typography gutterBottom variant="h5">
              發票資訊
            </Typography>
            <Paper square elevation={1} className={classes.paper}>
              <div className={classes.header}>
                <Typography variant="caption" color="textSecondary">
                  發票日期
                </Typography>
                <Typography className={classes.date}>
                  {format(
                    new Date(invoice.invoiceCreateDateString),
                    "yyyy年MM月dd日"
                  )}
                </Typography>
                <Hidden smUp>
                  <div style={{ marginTop: 24 }}>
                    <Typography variant="body2" gutterBottom>
                      發票號碼 {invoice.invoiceNumber}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {invoice.sellerName} {invoice.sellerIdentifier}
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      size="small"
                      color="primary"
                      onClick={handleClick}
                    >
                      會員載具歸戶
                    </Button>
                  </div>
                </Hidden>
              </div>
              <Grid container alignItems="stretch">
                <Grid item xs={12} sm={6}>
                  <Hidden xsDown>
                    <div>
                      <Typography variant="body2" gutterBottom>
                        發票號碼 {invoice.invoiceNumber}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {invoice.sellerName} {invoice.sellerIdentifier}
                      </Typography>
                      <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        color="primary"
                        onClick={handleClick}
                      >
                        會員載具歸戶
                      </Button>
                    </div>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {displayValues(
                    [
                      {
                        key: "發票金額",
                        value: ccyformat(invoice.invoiceTotalAmount),
                      },
                      {
                        key: "買方統編",
                        value: invoice.buyerIdentifier,
                      },
                      {
                        key: "營業稅別",
                        value: invoice.invoiceType
                          ? InvoiceType[invoice.invoiceType]
                          : undefined,
                      },
                      {
                        key: "發票隨機碼",
                        value: invoice.invoiceRandomNumber,
                      },
                      {
                        key: "發票狀態",
                        value: invoice.invoiceStatus,
                      },
                      {
                        key: "載具顯碼",
                        value: invoice.invoiceCarrierId1,
                      },
                      {
                        key: "捐贈碼",
                        value: invoice.invoiceNPOBAN,
                      },
                      {
                        key: "備註",
                        value: invoice.invoiceMainRemark,
                      },
                    ],
                    ({ key, value }) => (
                      <div className={classes.item} key={key}>
                        <Typography
                          variant="body2"
                          className={classes.itemTitle}
                          display="inline"
                        >
                          {key}：
                        </Typography>
                        <span className={classes.right}>{value}</span>
                      </div>
                    )
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div className={classes.section}>
            <Typography gutterBottom variant="h5">
              產品明細
            </Typography>
            <Paper square elevation={1} className={classes.paper}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>商品品名</TableCell>
                      <TableCell align="right">商品數量</TableCell>
                      <TableCell align="right">單品項價格</TableCell>
                      <TableCell align="right">小計</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.invoiceProductList.map((invoiceProduct, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {invoiceProduct.invoiceProductDescription}
                          </TableCell>
                          <TableCell align="right">
                            {ccyformat(invoiceProduct.invoiceProductQuantity)}{" "}
                            {invoiceProduct.invoiceProductUnit}
                          </TableCell>
                          <TableCell align="right">
                            {ccyformat(invoiceProduct.invoiceProductUnitPrice)}
                          </TableCell>
                          <TableCell align="right">
                            {ccyformat(invoiceProduct.invoiceProductAmount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell
                        rowSpan={3}
                        colSpan={2}
                        style={{ borderBottom: 0 }}
                      />
                      <TableCell>總計</TableCell>
                      <TableCell align="right">
                        {ccyformat(
                          calcTotal(
                            invoice.invoiceProductList,
                            "invoiceProductAmount"
                          ),
                          { round: true }
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div className={classes.section}>
            <Typography gutterBottom>注意事項說明：</Typography>
            <Typography variant="body2" gutterBottom>
              1. 若發票內容有問題，請洽詢開立店家/公司。
            </Typography>
            <Typography variant="body2" gutterBottom>
              2.
              依財政部令「統一發票使用辦法」因以網路銷售電子勞務予中華民國境內，個人買受人開立雲端發票，為方便您查詢雲端發票及兌領統一發票中獎獎金，請使用「財政部統一發票兌獎APP」或
              「財政部電子發票整合服務平台」【https://einvoice.nat.gov.tw】，將連結雲端發票之載具(即消費者之電子郵件信箱)歸戶至手機條碼或自然人憑證條碼,由財政部提供查詢發票、自動對獎及中獎主動通知服務。
            </Typography>
            <Typography variant="body2" gutterBottom>
              3. 開獎日(25日零時)
              前已歸戶之會員載具，開獎後本公司將不提供中獎發票證明聯，請於領獎期間內使用「統一發票兌獎」APP領獎，或至超商KIOSK以共通性載具查詢與列印中獎發票證明聯領獎。
            </Typography>
            <Typography variant="body2" gutterBottom>
              4. 使用會員載具的中獎發票，若
              無進行歸戶，開立店家/公司將主動電子發票證明聯郵寄給消費者。
            </Typography>
            <Typography variant="body2" gutterBottom>
              5. 使用共通性載具的中獎發票，若 無進行歸戶
              或已進行歸戶但未在大平台設定銀行帳戶，消費者可自行至媒體服務站(如:ibon)查詢並列印。
            </Typography>
            <Typography variant="body2" gutterBottom>
              6.
              使用會員載具或共通性載具的中獎發票，若已進行歸戶且已在大平台設定銀行帳戶，財政部會自動將中獎獎金匯款至消費者的銀行帳戶。
            </Typography>
            <Typography variant="body2" gutterBottom>
              7. 買受人為公司時，中獎狀態則顯示「無法兌獎」。
            </Typography>
          </div>
        </Container>
      </Main>
    </>
  );
};

export default Invoice;
