export enum InvoiceType {
  "07" = "一般稅額",
  "08" = "特種稅額",
}

export type InvoiceProduct = {
  invoiceProductId: string;
  invoiceProductDescription: string;
  invoiceProductQuantity: number;
  invoiceProductUnit: number;
  invoiceProductUnitPrice: number;
  invoiceProductAmount: number;
};

export type Invoice = {
  invoiceId: string;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceTotalAmount: number;
  buyerIdentifier: number;
  invoiceType: "07" | "08";
  invoiceRandomNumber: string;
  invoiceStatus: string;
  invoiceCarrierId1: string;
  invoiceMainRemark: string;
  invoiceNPOBAN: string;
  invoiceProductList: [];
};
