import actionCreators from "redux/actionCreators";
import { createSelector } from "reselect";
import getIn from "@e-group/utils/getIn";
/** Actions */
export const {
  fetchGetInvoice,
  fetchGetInvoiceRequest,
  fetchGetInvoiceSuccess,
  fetchGetInvoiceFailure,

  fetchPostCarrierMemberVan,
  fetchPostCarrierMemberVanRequest,
  fetchPostCarrierMemberVanSuccess,
  fetchPostCarrierMemberVanFailure,
} = actionCreators.publicPages.invoice;

/** Types */
export const FETCH_GET_INVOICE = fetchGetInvoice().type;
export const FETCH_GET_INVOICE_REQUEST = fetchGetInvoiceRequest().type;
export const FETCH_GET_INVOICE_SUCCESS = fetchGetInvoiceSuccess().type;
export const FETCH_GET_INVOICE_FAILURE = fetchGetInvoiceFailure().type;

export const FETCH_POST_CARRIER_MEMBER_VAN = fetchPostCarrierMemberVan().type;
export const FETCH_POST_CARRIER_MEMBER_VAN_REQUEST = fetchPostCarrierMemberVanRequest()
  .type;
export const FETCH_POST_CARRIER_MEMBER_VAN_SUCCESS = fetchPostCarrierMemberVanSuccess()
  .type;
export const FETCH_POST_CARRIER_MEMBER_VAN_FAILURE = fetchPostCarrierMemberVanFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = (state) =>
  getIn(state, ["apis", "publicPages", "invoice"], {});

const getFetchGetInvoice = createSelector(getApiStates, (apiStates) =>
  getIn(apiStates, ["fetchGetInvoice"], {})
);

const getFetchPostCarrierMemberVan = createSelector(getApiStates, (apiStates) =>
  getIn(apiStates, ["fetchPostCarrierMemberVan"], {})
);

export const getInvoice = createSelector(
  getFetchGetInvoice,
  (fetchResult) => fetchResult.response
);

export const getCarrierMemberVan = createSelector(
  getFetchPostCarrierMemberVan,
  (fetchResult) => fetchResult.response
);
