import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchPostCarrierConsumer,
  getCarrierConsumer,
  getCarrierConsumerError,
} from "./redux";

import FixedCenter from "@e-group/material-layout/FixedCenter";
import Center from "@e-group/material-layout/Center";
import { Typography, CircularProgress } from "@material-ui/core";
import useLocationSearch from "utils/useLocationSearch";

const CarrierVerify: React.FunctionComponent = () => {
  const formEl = React.useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const carrierConsumer = useSelector(getCarrierConsumer);
  const carrierConsumerError = useSelector(getCarrierConsumerError);
  const { emailTokenId } = useLocationSearch();

  React.useEffect(() => {
    dispatch(
      fetchPostCarrierConsumer({
        emailTokenId,
      })
    );
  }, [dispatch, emailTokenId]);

  React.useEffect(() => {
    if (carrierConsumer && formEl && formEl.current) {
      formEl.current.submit();
    }
  }, [carrierConsumer, dispatch, emailTokenId]);

  if (!emailTokenId) {
    return (
      <Center>
        <Typography variant="h6">無效的網址</Typography>
      </Center>
    );
  }

  if (carrierConsumerError) {
    return (
      <Center>
        <Typography variant="h6">
          {carrierConsumerError.response.data.clientMessage}
        </Typography>
      </Center>
    );
  }

  return (
    <>
      {carrierConsumer && (
        <form
          method="POST"
          style={{ display: "none" }}
          ref={formEl}
          action={carrierConsumer.postUrl}
        >
          <input
            type="hidden"
            name="card_ban"
            value={carrierConsumer.card_ban}
          />
          <input
            type="hidden"
            name="card_no1"
            value={carrierConsumer.card_no1}
          />
          <input
            type="hidden"
            name="card_no2"
            value={carrierConsumer.card_no2}
          />
          <input
            type="hidden"
            name="card_type"
            value={carrierConsumer.card_type}
          />
          <input type="hidden" name="token" value={carrierConsumer.token} />
          <input
            type="hidden"
            name="signature"
            value={carrierConsumer.signature}
          />
        </form>
      )}
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    </>
  );
};

export default CarrierVerify;
