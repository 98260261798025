import React from "react";

import { renderRoutes, RouteConfigComponentProps } from "react-router-config";

import NavbarBrick from "@e-group/material-layout/NavbarBrick";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const Root: React.FunctionComponent<RouteConfigComponentProps> = ({
  route = {},
}) => {
  return (
    <>
      <AppBar elevation={1} color="default">
        <Toolbar variant="dense">
          <Typography variant="h6" noWrap>
            eGroup Invoice
          </Typography>
        </Toolbar>
      </AppBar>
      <NavbarBrick dense />

      {renderRoutes(route.routes)}
    </>
  );
};

export default Root;
