/**
 * format currency
 * @param {string | number} currency
 * @param {object} options
 */
const ccyformat = (currency, options = {}) => {
  if (!currency) return undefined;
  const { round, maximumFractionDigits = 6 } = options;

  let nextCurrency = Number(Number(currency).toFixed(6));
  if (round) {
    nextCurrency = Math.round(nextCurrency);
  }
  return nextCurrency.toLocaleString("zh-TW", {
    style: "currency",
    currency: "TWD",
    maximumFractionDigits,
  });
};

export default ccyformat;
